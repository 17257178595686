'use strict';

// Dependancies
import "custom-event-polyfill"
import "babel-polyfill"

// Modules
import preloader from './modules/preloader';
import videoPlayer from './modules/videoPlayer';
import videoPlaylist from './modules/videoPlaylist';
import menuBtn from './modules/menuBtn';
import tabs from './modules/tabs';
import stickyNav from './modules/stickyNav';
import theatreMode from './modules/theatreMode';
import showHideHeader from './modules/showHideHeader';
import parallax from './modules/parallax';
import footerMenu from './modules/footerMenu';
import ticketsPopup from './modules/ticketsPopup';
import mailchimp from './modules/mailchimp';
import faqPopup from './modules/faqPopup';

// Global Functionality
preloader.init();
menuBtn.init();
footerMenu.init();
mailchimp.init();

// Conditional Functionality
const m = [
  { 
    o : tabs,                 
    i : tabs.container 
  },
  { 
    o : stickyNav,                 
    i : stickyNav.container 
  },
  { 
    o : theatreMode,                 
    i : theatreMode.container 
  },
  { 
    o : showHideHeader,                 
    i : showHideHeader.container 
  },
  { 
    o : parallax,                 
    i : parallax.container 
  },
  { 
    o : ticketsPopup,                 
    i : ticketsPopup.container 
  },
  { 
    o : videoPlayer,                 
    i : videoPlayer.container 
  },
  { 
    o : videoPlaylist,                 
    i : videoPlaylist.container 
  },
  { 
    o : faqPopup,                 
    i : faqPopup.container 
  },
].map( a => (a.i) ? a.o.init() : null );