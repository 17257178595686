'use strict'

const self = {
	preloader: null,
	preloadContent: null,
	duration: null,

	init: () => {
		self.setupElements();
		self.registerEvents();
	},

	setupElements : () => {
		self.preloader = document.querySelector('.preloader');
		self.preloadContent = document.querySelector('.page-content');
		self.duration = 500;		
		self.videoEvent = new CustomEvent('startVideoAutoPlay');
	},

	registerEvents: () => {

		// Wait until after page load
		document.addEventListener('readystatechange', event => {

			// Extending duration for the homepage
			if ( document.querySelector('body.front') ) {
				self.duration = 1000;
			}
		
			// External resouces loaded
			if (event.target.readyState === "complete") {
				setTimeout( () => {
					if ( self.preloadContent ) {
						self.preloadContent.classList.add('page-content--loaded');
					}
					self.preloader.classList.add('preloader--loaded');
					document.dispatchEvent(self.videoEvent);
				}, self.duration);
				setTimeout( () => {
					if ( self.preloadContent ) {
						self.preloadContent.classList.add('page-content--ready');
					}
					self.preloader.parentNode.removeChild(self.preloader);
					document.dispatchEvent(self.videoEvent);
				}, (self.duration+500));

			}
		
		});
	}
}

export default self
