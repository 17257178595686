'use strict';

const self = {
  container: document.querySelector('#video-player'),
  player: null,
  video: null,
  controls: null,
  progress: null,
  progressBar: null,
  toggleButton: null,
  volume: null,
  speed: null,
  fullscreen: null,
  countrolsHideTimeout: null,

  init: () => {
    self.setupElements();
    self.registerEvents();
  },

  setupElements: () => {
    self.player = document.querySelector('.video-panel__wrapper');
    self.video = document.querySelector('#video-player');
    self.controls = document.querySelector('.player__controls');
    self.progress = document.querySelector('.progress');
    self.progressBar = document.querySelector('.progress__filled');
    self.toggleButton = document.querySelector('.player__button');
    self.volume = document.querySelector('.playerVolume');
    self.speed = document.querySelector('.playerSpeed');
    self.fullscreen = document.querySelector('.toggleFullscreen');
    self.playlistItems = [...document.querySelectorAll('.playlist-item__link')];
  },

  registerEvents: () => {
    self.video.addEventListener('click', self.togglePlay);
    self.video.addEventListener('timeupdate', self.handleProgress);
    self.toggleButton.addEventListener('click', self.togglePlay);

    let mousedown = false;
    self.progress.addEventListener('click', self.handleSeek);
    self.progress.addEventListener('mousemove', e => mousedown && self.handleSeek(e));
    self.progress.addEventListener('mousedown', () => (mousedown = true));
    self.progress.addEventListener('mouseup', () => (mousedown = false));

    document.addEventListener('mousemove', self.toggleControls);
    self.video.addEventListener('mouseover', () => {
      clearTimeout(self.countrolsHideTimeout);
    });

    document.addEventListener('startVideoAutoPlay', () => {
      var promise = document.querySelector('.video-panel__player').play();

      if (promise !== undefined) {
        promise
          .then(_ => {
            // Autoplay started!
          })
          .catch(error => {
            self.toggleButton.classList.remove('togglePlayback');
            self.controls.classList.add('player__controls--visible');
          });
      }
    });

    // When playlist items clicked, handle video player controls
    self.playlistItems.forEach(playlistItem => {
      playlistItem.addEventListener('click', self.handleControls);
    });
  },

  toggleControls: e => {
    if (e.target == self.video) {
      self.handleControls();
    }
  },

  handleControls: () => {
    clearTimeout(self.countrolsHideTimeout);
    self.controls.classList.add('player__controls--visible');
    self.countrolsHideTimeout = setTimeout(() => {
      self.controls.classList.remove('player__controls--visible');
    }, 3000);
  },

  togglePlay: () => {
    if (self.video.paused) {
      self.video.play();
      self.toggleButton.classList.add('togglePlayback');
    } else {
      self.video.pause();
      self.toggleButton.classList.remove('togglePlayback');
      self.controls.classList.add('player__controls--visible');
    }
  },

  handleProgress: () => {
    const percent = (self.video.currentTime / self.video.duration) * 100;

    // if isn't NaN
    if (self.video.duration === self.video.duration) {
      self.progressBar.style.flexBasis = `${percent}%`;
      // document.querySelector('.time').textContent = `${self.prettifyTime(self.video.currentTime)}/${self.prettifyTime(self.video.duration)}`;
    }
  },

  handleSeek: e => {
    const seekTime = (e.offsetX / self.progress.offsetWidth) * self.video.duration;
    self.video.currentTime = seekTime;
  },

  prettifyTime: time => {
    time = Math.round(time);
    var hr = ~~(time / 3600);
    var min = ~~((time % 3600) / 60);
    var sec = time % 60;
    var sec_min = '';
    if (hr > 0) {
      sec_min += '' + hrs + ':' + (min < 10 ? '0' : '');
    }
    sec_min += '' + min + ':' + (sec < 10 ? '0' : '');
    sec_min += '' + sec;
    return sec_min;
  }
};

export default self;
