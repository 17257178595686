'use strict'

const self = {
	inputs: null,

	init: () => {
		self.setupElements();
		self.registerEvents();
	},

	setupElements : () => {
		self.inputs = [...document.querySelectorAll('.mc-field-group__input')];
	},

	registerEvents: () => {
		self.inputs.forEach( input => {

			// When user is inputting text, hide the label
			input.addEventListener('focus', (e) => {
				input.parentElement.querySelector('.mc-field-group__label').classList.add('mc-field-group__label--hide');
			});


			// When the user blurs on input, hide the label if there is value, otherwise, show label
			input.addEventListener('blur', (e) => {
				if ( input.value.length === 0 ) {
					input.parentElement.querySelector('.mc-field-group__label').classList.remove('mc-field-group__label--hide');
				}
			});
		});
	}

}

export default self
