"use strict";

const self = {
	container: document.querySelector('body.front'),
	theatreButton: null,
	duration: null,
	panels: null,
	videoContent: null,
	videoContentParent: null,
	videoContentFadeOutClass: null,
	videoPlayer: null,
	videoPlayerFadeOutClass: null,
	theatreClass: null,
	isFirstLoad: null,
	aspectRatio: null,
	windowResizeCheck: null,

	init: () => {
		self.setupElements();
		self.registerEvents();
		self.enableScrollTrigger();
		self.setupTheatreScreen();
	},

	setupElements: () => {
		self.theatreButton = document.querySelector('.home-content-panel__control-btn');
		self.duration = 1000;
		self.panels = document.querySelector('.front-page-panels');
		self.videoContent = document.querySelector('.home-content-panel__wrapper');
		self.videoContentParent = document.querySelector('.home-content-panel');
		self.videoContentFadeOutClass = 'home-content-panel__wrapper--animating';
		self.videoPlayerWrapper = document.querySelector('.video-panel__wrapper');
		self.videoPlayer = document.querySelector('.video-panel__player');
		self.videoPlayerWrapperFadeOutClass = 'video-panel__wrapper--animating';
		self.videoPlayerParent = document.querySelector('.video-panel');
		self.videoPlayerParentAnimateClass = 'video-panel--animate-width';
		self.theatreClass = 'front-page-panels--fullscreen';
		self.isFirstLoad = true;
		self.aspectRatio = 2.36;
		self.footer = document.querySelector('.footer');
		self.windowResizeCheck = window.innerWidth;

	},

	registerEvents: () => {
		self.theatreButton.addEventListener('click', e => {
			e.preventDefault();
			self.triggerTheatre();

			if ( !self.panels.classList.contains(self.theatreClass) ) {
				self.enableScrollTrigger();
			}
		});

		window.addEventListener('resize', () => {
			if(window.innerWidth != self.windowResizeCheck ){
				self.videoContentParent.classList.add('home-content-panel--initial');
				self.videoPlayerWrapper.classList.add('video-panel__wrapper--initial');
				self.setupTheatreScreen();
	
				self.windowResizeCheck = window.innerWidth;
			  }
		});
	},

	isMobile: () => { 
		if(window.innerWidth > 991) {
			return false
		} else {
			return true;
		}
	},

	triggerTheatre: (disable = false) => {
		if ( self.panels.classList.contains(self.theatreClass) ) {
			disable = true;
		}

		// Disable theatre mode.
		if ( disable ) {

			// Delay to allow CSS3 animations.
			setTimeout( () => {
				self.panels.classList.remove(self.theatreClass);
				self.resizeTheatre(true);	
				document.body.classList.remove('noscroll');
			}, (self.duration/2));
	
			// Begin fadeout.
			self.videoContent.classList.add(self.videoContentFadeOutClass);
			self.videoPlayerWrapper.classList.add(self.videoPlayerWrapperFadeOutClass);
			self.videoPlayerParent.classList.add(self.videoPlayerParentAnimateClass);
			self.scrollTo(0, 500);
	
			setTimeout( () => {
				// Allow CSS3 animations to finish before removing.
				self.videoContent.classList.remove(self.videoContentFadeOutClass);
				self.videoPlayerWrapper.classList.remove(self.videoPlayerWrapperFadeOutClass);
				self.videoPlayerParent.classList.remove(self.videoPlayerParentAnimateClass);

				if ( self.isMobile() ) {					
					self.resizeTheatre();
				}
			}, self.duration);
			
		} else {
		// Enable theatre mode.

			// Delay to allow CSS3 animations.
			setTimeout( () => {
				self.panels.classList.add(self.theatreClass);
				self.resizeTheatre(false);
				document.body.classList.add('noscroll');
			}, (self.duration/2));
	
			// Begin fadeout.
			self.videoContent.classList.add(self.videoContentFadeOutClass);
			self.videoPlayerWrapper.classList.add(self.videoPlayerWrapperFadeOutClass);
			self.videoPlayerParent.classList.add(self.videoPlayerParentAnimateClass);
			self.scrollTo(0, 500);
	
			setTimeout( () => {
				// Allow CSS3 animations to finish before removing.
				self.videoContent.classList.remove(self.videoContentFadeOutClass);
				self.videoPlayerWrapper.classList.remove(self.videoPlayerWrapperFadeOutClass);
				self.videoPlayerParent.classList.remove(self.videoPlayerParentAnimateClass);
			}, self.duration);
		}
	},

	enableScrollTrigger: () => {
		window.addEventListener('wheel', () => {
			// Once preloading is done ...
			if ( ! document.querySelector('.preloader') ) {
				// Only if it's threatre mode, otherwise ignore.
				if ( self.panels.classList.contains(self.theatreClass) ) {
					if ( self.panels.classList.contains(self.theatreClass) ) {
						self.triggerTheatre();
					}
				}
			}
		});
	},

	setupTheatreScreen: () => {
		if ( self.isFirstLoad ) {
			self.resizeTheatre();	
			self.isFirstLoad = false;
			document.body.classList.add('noscroll');
			if ( self.isMobile() ) {
				self.triggerTheatre(true);
			}
		} else {
			if ( !self.isMobile() ) {
				self.videoContentParent.classList.add('home-content-panel--initial');
				self.videoPlayerWrapper.classList.add('video-panel__wrapper--initial');
			}
			setTimeout(() => {
				if ( self.isMobile() ) {
					self.triggerTheatre(true);
				} else {
					self.resizeTheatre();
				}
			}, 500);
		}
	},

	resizeTheatre: (disable = false) => {
		if ( disable ) {
			self.videoPlayer.removeAttribute('style');
			self.videoContentParent.removeAttribute('style');
			self.videoPlayerWrapper.removeAttribute('style');
		} else {
			if ( self.panels.classList.contains(self.theatreClass) ) {
				let windowHeight = window.innerHeight;
				let videoContentHeight = 200;
				let height = windowHeight - videoContentHeight;
				let videoWidth = height*self.aspectRatio;
		
				// responsive video
				// self.videoPlayer.style.width = `${videoWidth}px`;
				// self.videoPlayer.style.height = `${height+1000}px`;
				// self.videoPlayer.style.left = '50%';
				// self.videoPlayer.style.marginLeft = `-${videoWidth/2}px`;
				self.videoContentParent.style.paddingTop = `${height}px`;
				self.videoPlayerWrapper.style.paddingTop = `${height}px`;
		
				setTimeout( () => {
					self.videoContentParent.classList.remove('home-content-panel--initial');
					self.videoPlayerWrapper.classList.remove('video-panel__wrapper--initial');
				}, 500);
			} else {
				self.videoContentParent.classList.remove('home-content-panel--initial');
				self.videoPlayerWrapper.classList.remove('video-panel__wrapper--initial');
			}
		}
	},

	scrollTo: (to, duration) => {
		
		const
		element = document.scrollingElement || document.documentElement,
		start = element.scrollTop,
		change = to - start,
		startDate = +new Date(),

		// t = current time
		// b = start value
		// c = change in value
		// d = duration
		easeInOutQuad = (t, b, c, d) => {
			t /= d/2;
			if (t < 1) return c/2*t*t + b;
			t--;
			return -c/2 * (t*(t-2) - 1) + b;
		},

		animateScroll = () => {
			const currentDate = +new Date();
			const currentTime = currentDate - startDate;
			element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
			if( currentTime < duration) {
				requestAnimationFrame(animateScroll);
			} else {
				element.scrollTop = to;
			}
		};

		animateScroll();
	}

};

export default self;
