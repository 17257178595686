'use strict'

const self = {
	container: document.querySelector('.faq-popup'),
	inputs: null,

	init: () => {
		self.setupElements();
		self.registerEvents();
	},

	setupElements : () => {
		self.linkOpen = document.querySelector('.faq-popup__trigger');
		self.linkClose = document.querySelector('.faq-popup__close');
	},

	registerEvents: () => {
		self.linkOpen.addEventListener('click', e => {
			e.preventDefault();
			self.container.classList.add('faq-popup--open');
		});

		self.linkClose.addEventListener('click', e => {
			e.preventDefault();
			self.container.classList.remove('faq-popup--open');
		});

		document.body.addEventListener('click', e => {
			// If user clicks outside of popup content, trigger close
			if ( self.container.classList.contains('faq-popup--open') ) {
				if ( self.container === e.target ) {
					self.container.classList.remove('faq-popup--open');
				}
			}
		});
	}

}

export default self
