"use strict";

const self = {
	container: document.querySelector('.page-heading'),
	lastScrollTop: null,
	start: null,
	headerImageHeight: null,
	header: null,
	pageContent: null,
	innerWrap: null,
	area: null,

	init: () => {
		self.setupElements();
		self.registerEvents();
		self.onScroll();
	},

	setupElements: () => {
		self.lastScrollTop = 0;
		self.start = document.querySelector('#header').clientHeight/3;
		self.headerImageHeight = document.querySelector('.page-heading').clientHeight;
		self.header = document.querySelector('#header').classList;
		self.sidebar = document.querySelector('.sidebar-nav');
		self.pageContent = document.querySelector(`.page-content`);
		self.innerWrap = document.querySelector(`.inner-wrap`);
		self.area = self.pageContent.offsetTop + self.innerWrap.offsetTop;
	},

	registerEvents: () => {
		window.addEventListener('scroll', self.onScroll);
	},	 

	onScroll: (e) => {
		// Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
		let st = window.pageYOffset || document.documentElement.scrollTop;

		if (st > self.lastScrollTop){
			// On downscroll
		
			// Should the header be fixed or not?
			if ( st > self.start && !self.header.contains('header--fixed') ) {
				self.header.add('header--fixed');
		
				if (!self.header.contains('header--scrolled')) {
					self.header.add('header--scrolled');
				}
			}
		
			if (st > self.start &&  st < self.headerImageHeight) {
				// Animate the header
				if ( !self.header.contains('header--scrolled') ) {
					self.hideOrShowHeader(st, true);
					setTimeout( () => {
						self.header.add('header--scrolled');
					}, 200);
					setTimeout( () => {
						self.hideOrShowHeader(st, false);
					}, 300);
				}
			} else if (st > self.headerImageHeight) {
				// Once image header is out of screen, hide the fixed header
				self.hideOrShowHeader(st, true);
			}
		
		} else if ( st < self.lastScrollTop ) {
			// On upscroll

			if (st > self.start) {
				// Hide the header when scrolling down
				if (st > self.headerImageHeight) {
					self.hideOrShowHeader(st, false);
				}

				// If sidebar on page
				if ( self.sidebar && st <= self.headerImageHeight ) {					
					self.sidebar.classList.add('sidebar-nav--header-is-fixed');
				}

			} else if (st < self.start) {
				// If at the top, remove classes
				self.header.remove('header--scrolled');
				self.header.remove('header--fixed');
			} else {  
				// Show the header when scrolling up
				self.hideOrShowHeader(st, false);
			}
		}
		
		self.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
	},

	hideOrShowHeader: (st, hide) => {
		if ( hide ) {
			if ( self.sidebar ) {
				// Remove sidebar offset
				self.sidebar.classList.remove('sidebar-nav--header-is-fixed');
			}
			self.header.add('header--hidden');
		} else {
			if ( self.sidebar && st >= self.headerImageHeight ) {
				// Add sidebar offset, so it's not hidden by fixed header
				self.sidebar.classList.add('sidebar-nav--header-is-fixed');
			}
			self.header.remove('header--hidden');
		}
	}
};

export default self;
