"use strict";

const self = {
	container: document.querySelector(".page-fixed-nav__sidebar"),
	offset: 0,
	sidebarClass: null,
	sidebarFixedClass: null,
	sidebarLinkClass: null,
	sidebarLinkActiveClass: null,
	sidebar: null,
	sidebarTop: null,
	stickyHeaderOffset: null,
	sidebarLink: null,
	sidebarActiveLink: null,
	currentSection: null,
	sectionClass: null,
	sections: null,
	scrollFunctions: header,
	event: null,
	responsiveOffsetTop: null,
	responsiveOffsetBottom: null,
	
	init: () => {
		self.setupElements();
		self.registerEvents();
		self.setActiveLink();
		self.stickySidebar();
	},

	setupElements: () => {
		self.offset = 20;
		self.sidebarClass = "sidebar-nav";
		self.sidebarFixedClass = "sidebar-nav--sticky";
		self.sidebarFixedBottomClass = "sidebar-nav--sticky-bottom";
		self.sidebarLinkClass = "sidebar-nav__link";
		self.sidebarLinkActiveClass = 'sidebar-nav__link--active',
		self.sidebar = document.querySelector(`.${self.sidebarClass}`);
		self.sidebarLink = [...document.querySelectorAll(`.${self.sidebarLinkClass}`)];
		self.sidebarActiveLink = document.querySelector(`.${self.sidebarLinkActiveClass}`);
		self.currentSection = document.querySelector(self.sidebarActiveLink.getAttribute('href'));
		self.pageContent = document.querySelector(`.page-content`);
		self.innerWrap = document.querySelector(`#content`);
		self.sidebarTop =
			self.pageContent.offsetTop +
			self.innerWrap.offsetTop -
			self.offset;
		self.stickyHeaderOffset = 100;
		self.sectionClass = 'content-section';
		self.sections = [...document.querySelectorAll(`.${self.sectionClass}`)];
		self.pageContent = document.querySelector(`.page-content`);
		self.responsiveOffsetTop = 0;
		self.responsiveOffsetBottom = 0;
	},

	registerEvents: () => {
		window.addEventListener('scroll', () => {
			self.stickySidebar();
			self.setActiveLink();
		});
		
		window.addEventListener('resize', () => {
			self.stickySidebar();
			self.setActiveLink();
		});

		self.sidebarLink.forEach(link => {
			link.addEventListener('click', e => {
				e.preventDefault();
				let targetSection = document.querySelector( e.target.getAttribute('href') );
				self.scrollTo(self.sidebarTop + targetSection.offsetTop, 1000);
			});
		});

	},

	isMobile: () => { 
		if(window.innerWidth > 991) {
			return false
		} else {
			return true;
		}
	},

	stickySidebar: () => {

		if ( self.isMobile() ) {
			// self.responsiveOffset is just for tablet, need browser size check
			self.responsiveOffsetTop = 102;
			self.responsiveOffsetBottom = 115;
		} else {
			self.responsiveOffsetTop = 0;
			self.responsiveOffsetBottom = 0;
		}

		// If window is past sidebar top position, make it fixed
		if (window.scrollY > self.sidebarTop - self.responsiveOffsetTop) {
			self.sidebar.classList.add(self.sidebarFixedClass);
		} else if (window.scrollY < self.sidebarTop - self.stickyHeaderOffset)  {
			self.sidebar.classList.remove(self.sidebarFixedClass);
		} 
		
		// If window scrolls into the footer, make sidebar fixed (but to just above the footer)
		if (window.scrollY >= self.pageContent.offsetTop + self.pageContent.clientHeight - self.responsiveOffsetBottom - self.sidebar.clientHeight )  {
			self.sidebar.classList.add(self.sidebarFixedBottomClass);
			self.sidebar.style.position = 'absolute';
			self.sidebar.style.top = `${self.currentSection.offsetTop + self.currentSection.clientHeight - self.sidebar.clientHeight}px`;
		} else {
			self.sidebar.classList.remove(self.sidebarFixedBottomClass);
			self.sidebar.removeAttribute('style');
		}
	},

	setActiveLink: () => {		
		let area = self.pageContent.offsetTop + self.innerWrap.offsetTop; // Get the top positions of parent wrappers
		let offset = 100;

		self.sections.forEach(section => {
			let sectionStart = section.offsetTop;
			let sectionLength = section.clientHeight;

			// If window is between the start of section, and the height (length) of section (minus offset)
			// then change active link in the sidebar accordingly
			if ( 
				(sectionStart + area) >= (window.scrollY - (sectionLength + area) - offset) &&
				(sectionStart + area) <= (window.scrollY + offset )
			) {
				self.currentSection = document.querySelector(`#${section.getAttribute('id')}`);
				let target = document.querySelector(`[href="#${section.getAttribute('id')}"]`);
				
				if ( self.sidebarActiveLink != target ) {
					self.sidebarActiveLink.classList.remove('sidebar-nav__link--active');
					target.classList.add('sidebar-nav__link--active');
					self.sidebarActiveLink = target;
				}
			}
		});
	},

	scrollTo: (to, duration) => {
		const
		element = document.scrollingElement || document.documentElement,
		start = element.scrollTop,
		change = to - start,
		startDate = +new Date(),

		// t = current time
		// b = start value
		// c = change in value
		// d = duration
		easeInOutQuad = (t, b, c, d) => {
			t /= d/2;
			if (t < 1) return c/2*t*t + b;
			t--;
			return -c/2 * (t*(t-2) - 1) + b;
		},

		animateScroll = () => {
			const currentDate = +new Date();
			const currentTime = currentDate - startDate;
			element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
			if( currentTime < duration) {
				requestAnimationFrame(animateScroll);
			} else {
				element.scrollTop = to;
			}
		};

		animateScroll();
	}
};

export default self;
