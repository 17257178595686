'use strict'

const self = {
	container: document.querySelector('.tickets-popup'),
	button: null,
	popup: null,

	init: () => {
		self.setupElements();
		self.registerEvents();
		setTimeout(self.togglePopup, 2000);
	},

	setupElements : () => {
		self.button = document.querySelector('.tickets-popup__close');
		self.popup = document.querySelector('.tickets-popup');
	},

	registerEvents: () => {
		self.button.addEventListener('click', (e) => {
			e.preventDefault();
			self.togglePopup();
		});
	},

	togglePopup: () => {
		if ( !self.popup.classList.contains('tickets-popup--show') ) {
			self.popup.classList.add('tickets-popup--show');
		} else {
			self.popup.classList.remove('tickets-popup--show');
		}
	}

}

export default self
