'use strict'

const self = {
	container: document.querySelector('.content-section__tabs'),
	tabContentTitle: null,
	tabLink: null,
	currentTabLink: null,
	currentTabContent: null,
	tabContentWrapper: null,
	tabLinkClassName: null,
	tabLinkActiveClass: null,
	tabContentClassName: null,
	tabContentActiveClass: null,
	tabContentHiddenClass: null,
	tabContentWrapperAnimateClass: null,
	tabHeight: null,
	duration: 0,
	event,

	init: () => {
		self.setupElements();
		self.registerEvents();
		document.addEventListener('startVideoAutoPlay', self.setHeight );
		
	},

	setupElements : () => {
		self.tabLinkClassName = 'tab-item';
		self.tabLinkActiveClass = 'tab-item--active';
		self.tabContentClassName = 'tab-content__item';
		self.tabContentActiveClass = 'tab-content__item--active';
		self.tabContentHiddenClass = 'tab-content__item--hidden';
		self.tabContentWrapperAnimateClass = 'tab-content--animate';
		self.tabLink = [...document.querySelectorAll(`.${self.tabLinkClassName}`)];
		self.currentTabLink = document.querySelector(`.${self.tabLinkClassName}.${self.tabLinkActiveClass}`);
		self.currentTabContent = document.querySelector(`.${self.tabContentClassName}.${self.tabContentActiveClass}`);
		self.tabContentWrapper = document.querySelector('.tab-content');
		self.duration = 1000;
		self.event = new CustomEvent('gn_tabs.js');
	},

	registerEvents: () => {
		self.tabLink.forEach(tab => {
			tab.addEventListener('click', (e) => {
				e.preventDefault();
				self.setActiveTab(e.target);
				self.setActiveTabContent(e.target);
				document.dispatchEvent(self.event);
			});
		});
	},

	setActiveTab: (tab) => {
		self.currentTabLink.classList.remove(self.tabLinkActiveClass);
		self.currentTabLink = tab;
		if ( document.querySelector('#content-title') ) {
			document.querySelector('#content-title').textContent = tab.dataset.contentTitle;
		}
		tab.classList.add(self.tabLinkActiveClass);
	},

	setActiveTabContent: (tab) => {
		let target = tab.getAttribute('href');
		let content = document.querySelector(target);

		// Begin fadeout
		self.tabContentWrapper.classList.add(self.tabContentWrapperAnimateClass);
		
		setTimeout( () => {
			// Once faded out, switch out active / hidden classes for tabs
			self.currentTabContent.classList.add(self.tabContentHiddenClass);			
			self.currentTabContent.classList.remove(self.tabContentActiveClass);
			
			// New current tab
			self.currentTabContent = content;
			content.classList.remove(self.tabContentHiddenClass);
			content.classList.add(self.tabContentActiveClass);
			self.setHeight();

		}, (self.duration/2));

		setTimeout( () => {
			// After finishing animation, remove animation class
			self.tabContentWrapper.classList.remove(self.tabContentWrapperAnimateClass);
		}, self.duration);
	},

	setHeight: () => {		
		self.tabHeight = self.currentTabContent.clientHeight;
		self.tabContentWrapper.style.height = `${self.tabHeight}px`;
	}

}

export default self
