'use strict'

const self = {
	container: document.querySelector('#page-heading'),
	body: null,
	imageHeader: null,
	videoHeader: null,
	scrollArrow: null,
	mediaHeading: null,
	mediaContent: null,
	footer: null,

	init: () => {
		self.setupElements();
		self.registerEvents();
		self.parallax();
	},

	setupElements : () => {
		self.body = document.body;
		self.imageHeader = document.querySelector('.page-heading__image');
		self.videoHeader = document.querySelector('#video-header');
		self.scrollArrow = document.querySelector('.page-heading__scroll');
		self.mediaHeading = document.querySelector('.media__heading');
		self.mediaContent = document.querySelector('.media__content');
		self.footer = document.querySelector('.footer');
	},

	registerEvents: () => {
		window.addEventListener('scroll', self.parallax);
		window.addEventListener('resize', self.parallax);

		// tabs.js trigger
		document.addEventListener('gn_tabs.js', self.parallax);
	},

	parallax: () => {

		var yPosA = window.pageYOffset / 4;
		var yPosB = window.pageYOffset / 3;
		var yPosC = window.pageYOffset / 8;
		var yPosD = window.pageYOffset / 1400;
		
		if ( self.videoHeader ) {
			self.videoHeader.style.transform = `translate(0, ${yPosA}px)`;
		}

		if ( self.imageHeader ) {
			self.imageHeader.style.transform = `translate(0, ${yPosA}px)`;
		}

		self.scrollArrow.style.transform = `translate(0, ${yPosB-50}px)`;

		if ( self.mediaContent ) {
			self.body.style.backgroundColor = `rgba(0, 0, 0, ${yPosD})`;
			if ( yPosD > .5 ) {
				self.mediaContent.style.color = 'white';
			} else {
				self.mediaContent.style.color = 'black';
				// self.mediaContent.style.color = `rgba(${0+yPosE}, ${0+yPosE}, ${0+yPosE}, 1)`;
			}
		}

		if ( self.mediaHeading ) {
			self.mediaHeading.style.transform = `translate(0, -${yPosC-50}px)`;
		}
	},

	isElementInViewport: (el, adjust) => {
		var rect = el.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document. documentElement.clientHeight)+adjust &&
			rect.right <= (window.innerWidth || document. documentElement.clientWidth)
		);
	  }

}

export default self
