'use strict'

const self = {
	button: null,
	header: null,
	navigation: null,
	navOpen: null,
	overlayOpen: null,

	init: () => {
		self.setupElements();
		self.registerEvents();
	},

	setupElements : () => {
		self.button = document.getElementById('menu'),
		self.header = document.getElementById('header').classList,
		self.navigation = document.getElementById('navigation').classList,
		self.navOpen = 'header--nav-open',
		self.overlayOpen = 'nav-overlay--open'
	},

	registerEvents: () => {
		self.button.addEventListener('click', () => {
			if (self.header.contains(self.navOpen)) {
				self.header.remove(self.navOpen);
				self.navigation.remove(self.overlayOpen);
				document.body.classList.remove('noscroll');
			} else {
				self.header.add(self.navOpen);
				self.navigation.add(self.overlayOpen);
				document.body.classList.add('noscroll');
			}
		});
	}

}

export default self
