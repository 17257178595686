'use strict'

const self = {
	container: document.querySelector('.video-playlist'),
	videos: null,
	videoPlayerTitle: null,
	videoPlayer: null,

	init: () => {
		self.setupElements();
		self.registerEvents();
	},

	setupElements: () => {
		self.videos = [...document.querySelectorAll('.playlist-item__link')];
		self.videoPlayerTitle = document.querySelector('.video-title');
		self.videoPlayer = document.querySelector('#video-player');
	},

	registerEvents: () => {
		self.videos.forEach(video => {
			video.addEventListener('click', e => {
				e.preventDefault();

				let playlistItem = null;

				// select .playlist-item
				if ( video.parentElement.parentElement.classList.contains('playlist-item') ) {
					playlistItem = video.parentElement.parentElement;
				} else {
					playlistItem = video.parentElement.parentElement.parentElement;
				}

				// Update video player with new URL and video title
				self.videoPlayer.querySelector('source').setAttribute('src', playlistItem.getAttribute('data-video'));
				self.videoPlayerTitle.textContent = playlistItem.getAttribute('data-title');

				// Load up new video
				self.videoPlayer.load();
				self.videoPlayer.play();
			});
		});
	}
}

export default self;