'use strict'

const self = {
	footerLinks: null,

	init: () => {
		self.setupElements();
		self.registerEvents();
	},

	setupElements : () => {
		self.footerLinks = [...document.querySelectorAll('.footer-nav__link')];
	},

	registerEvents: () => {
		self.footerLinks.forEach( link => {
			link.addEventListener('click', (e) => {
				e.preventDefault();
				
				let alreadyOpened = document.querySelector('.footer-nav__item--show');
				let linkParent = e.target.parentElement.parentElement.classList;
				
				if (linkParent.contains('footer-nav__item--show')) {
					linkParent.remove('footer-nav__item--show');
				} else if (!linkParent.contains('footer-nav__item--show')) {
					linkParent.add('footer-nav__item--show');
				}
				
				if ( document.body.contains(alreadyOpened) ) {
					alreadyOpened.classList.remove('footer-nav__item--show');
				}

			});
		});

		// Clicking anywhere else, while a sub menu is open, will hide it
		document.addEventListener('click', (e) => { self.outsideClick(e) });
	},

	outsideClick: (e) => {
		if ( document.querySelector('.footer-nav__item--show') && !e.target.classList.contains('footer-nav__link') ) {
			document.querySelector('.footer-nav__item--show').classList.remove('footer-nav__item--show');
		}
	}

}

export default self
